import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Header from '../../../components/Header/navmap/landingVc';
import i18n from '../../../locale';
import sitemap from '../../../data/sitemap';
import Pricing from './Pricing';
import Clients from './Clients'

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.landingVc.price}
    header={Header}
    isLanding={'vc'}
    url='_url:landing-vc-pricing'
    homeLink={i18n('_url:landing-vc')}>
    <SEO {...seo} location={location}/>
    <Pricing/>
    <Clients/>
  </Layout>
);